
.darkToast.toast{
    background-color: grey ;
    color: white;
    transform: translate(-2%, -3%);
    box-shadow: 9px 9px 14px 1px rgba(89,86,89,0.82); 
}
.alertToast.toast{
    background-color: rgb(248, 184, 66) ;   
}
.toastupdate:hover{    
    transform: translate(-2%, -3%);
    box-shadow: 9px 9px 14px 1px rgba(89,86,89,0.82);
}
.toastupdate.toast{
    transition: transform 400ms ,hover 400ms;      
}

.formInventoryUpdate.card{
    box-shadow: 7px 7px 13px 2px rgba(89,86,89,0.82);
}

.modal-content{
   min-height: 72vh;  
}