.react-tags {
    width: 370px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-template-areas: 
    "search"
    "tags";
}

.react-tags__selected{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    grid-area: tags;
}
.react-tags__search-wrapper{
    display:grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 16px;
    grid-area: search;
}

.react-tags__search-input {
    width: 200px;
    height: 36px;
    left: 455px;
    top: 682px;;
    text-align: center;
    outline: none;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.react-tags__search-input:hover {
    box-shadow: 0 0 3pt 2pt #B0CAC7;
    opacity: 0.7;
}

.react-tags__search-input:focus {
    box-shadow: 0 0 3pt 2pt #B0CAC7;
    opacity: 0.7;
}
