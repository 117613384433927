.space-card.card:hover{
    background-color: #C0C0C0;
}
.space-card.card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.space-card img{
    border-radius: 40px; 
    height: 270px;
}