
.bg-primary {
    background-color: #B0CAC7 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
$primary: #B0CAC7;

.page-item.active .page-link{
    background-color: #B0CAC7;
    border-color: #B0CAC7;
}
.btn.btn-primary:focus {
    transform: translateY(-2px);
    box-shadow: 0px 0px 5px  #B0CAC7;
}

@import '~bootstrap/dist/css/bootstrap.min.css'

