body {
  margin: 0;
  padding: 0;
 overflow-x: hidden ;
}
.App{
  min-height: 81vh;
  display: grid;
  align-content: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*::after,*::before{
  box-sizing: border-box;
}

.btn.btn-primary {
  transition: all 0.3s;
  background-color:#B0CAC7;
  border-color: #B0CAC7;
}

.btn.btn-primary:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color:#B0CAC7;
  border-color: #B0CAC7;
}

.btn.btn-primary:active {
  transform: translateY(-2px);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  background-color:#B0CAC7 !important;
  border-color: #B0CAC7 !important;
  outline: none !important;
}
.btn.btn-primary:focus {
  transform: translateY(-2px);
  box-shadow: 0px 0px 5px #005086;
  background-color:#B0CAC7 !important;
  border-color: #B0CAC7 !important;
  outline: none !important;
}

