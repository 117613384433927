.response.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.response_card.card{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    animation: moveInBottom 1s;
    width:70vw;
}

#accepted{
    background-color: #A9CF54;
}

#rejected{
    background-color: #F1433F;
}

#pending{
    background-color: #F7E967;
}

.response_card.card-header{
    background: none !important;
}
.response_card.card-footer{
    background: none !important;
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(20%)
    }
  
    100% {
        opacity: 1;
        transform: translateY(0%); 
    }
}